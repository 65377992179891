import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import {
  connectWithFeedback,
  Behavior as ButtonBehavior,
  Appearance as ButtonAppearance,
  ConnectPropType as FeedbackPropType,
} from 'Shared/Button/Feedback';
import { whitelilac, mineshaft, pixelsToUnit, minMediumMediaQuery } from 'Shared/Style';
import Link from 'Shared/Link';

type PropType = {
  children: any;
  buttonCaption: string;
  heading?: string;
  redirectLabel?: string;
  redirectLink?: string;
  disabled?: boolean;
  customFormStyle?: any;
  buttonAction?: () => Promise<void>;
  successMessage?: React.ReactNode;
} & StyledProps &
  FeedbackPropType;

export default styled(
  connectWithFeedback({ behavior: ButtonBehavior.KeepEnabled })(
    class AccountForm extends React.Component<PropType> {
      onClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        e.preventDefault();
        return this.props.feedback.push(this.props.buttonAction());
      };
      render() {
        const FeedbackButton = this.props.feedback.Button;
        return (
          <FormContainer css={!!this.props.customFormStyle && this.props.customFormStyle}>
            {!!this.props.heading && <H1>{this.props.heading}</H1>}

            <styled.Form css={{ marginBottom: pixelsToUnit(20) }} noValidate>
              {this.props.children}
              <FeedbackButton
                css={this.props.compose()}
                appearance={[ButtonAppearance.Primary, ButtonAppearance.Full]}
                onClick={this.onClick}
                disabled={this.props.disabled}
                type="submit"
              >
                {this.props.buttonCaption}
              </FeedbackButton>
            </styled.Form>

            {!!this.props.redirectLink && (
              <styled.Div css={{ textAlign: 'center' }}>
                <RedirectLink to={this.props.redirectLink}>{this.props.redirectLabel}</RedirectLink>
              </styled.Div>
            )}
            {!!this.props.successMessage && this.props.successMessage}
          </FormContainer>
        );
      }
    },
  ),
);

const RedirectLink = styled(Link, {
  color: mineshaft,
  textAlign: 'center',
  textDecoration: 'underline',
});

const H1 = styled.h1({
  fontWeight: 'normal',
  marginBottom: pixelsToUnit(21),
  textAlign: 'center',
  [minMediumMediaQuery]: {
    textAlign: 'left',
  },
});

const FormContainer = styled.div({
  backgroundColor: whitelilac,
  borderRadius: '0.15rem',
  padding: {
    xy: pixelsToUnit(30),
  },
});
