/**
 * @ComponentFor ChangePasswordPageViewModel
 */
import * as React from 'react';
import connect from 'Shared/connect';
import { ConnectPropType } from 'Shared/Button/Feedback';
import { epiPropertyValue } from '@avensia/scope-episerver';
import { postJson, translate } from '@avensia/scope';
import { pixelsToUnit } from 'Shared/Style';
import AccountForm from 'Account/Form/AccountForm';
import ChangePasswordPageViewModel from './ChangePasswordPageViewModel.type';
import { Basic, Layout } from 'Shared/PageLayout/index';
import BreadcrumbsViewModel from 'Shared/Breadcrumbs/BreadcrumbsViewModel.type';
import SuccessMessage from 'Shared/SuccessMessage';
import PasswordVisibility from 'Shared/PasswordVisibility';
import MyPagesBaseLayout from 'Account/MyPages/MyPagesBaseLayout';
import { ErrorMessage } from 'Shared/FeedbackMessage';
import { styled } from '@glitz/react';

type ChangePasswordType = {
  inputs: any;
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showRepeatPassword: boolean;
  errors: { [item: string]: string };
  isSuccess: boolean;
  message?: string;
};
type RequiredProps = ChangePasswordPageViewModel & {
  breadcrumbs: BreadcrumbsViewModel[];
  currentBreakpoint: number;
};

type ConnectedProps = RequiredProps & ConnectPropType;

enum currentInput {
  currentPassword,
  newPassword,
  repeatPassword,
}
const initialInputValues = {
  currentPassword: '',
  newPassword: '',
  repeatPassword: '',
};

const TIME_SUCCESS_MESSAGE_DISPLAY = 2000; // milliseconds

class ChangePassword extends React.Component<ConnectedProps, ChangePasswordType> {
  constructor(props: ConnectedProps) {
    super(props);
    this.state = {
      inputs: {
        ...initialInputValues
      },
      showCurrentPassword: false,
      showNewPassword: false,
      showRepeatPassword: false,
      errors: {
        ...initialInputValues
      },
      isSuccess: false,
    };
  }

  setInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ inputs: { ...this.state.inputs, [e.currentTarget.name]: e.currentTarget.value } });
  };

  validateInputs = (inputs: any) => {
    let isValid = true;
    let errorMessageList = {};

    for (const [key, value] of Object.entries(inputs)) {
      if (!value) {
        isValid = false;
        errorMessageList = { ...errorMessageList, [key]: translate('/Account/ForgotPassword/EmailRequiredField') };
      }
    }
    this.setState({ errors: { ...this.state.errors, ...errorMessageList } });
    return isValid;
  };

  onSubmit = async () => {
    const isValid = this.validateInputs(this.state.inputs);
    if (isValid) {
      try {
        const { success, errors, message } = await postJson(this.props.changePasswordUrl, this.state.inputs);
        if (success) {
          this.setState(() => ({
            inputs: {
              currentPassword: '',
              newPassword: '',
              repeatPassword: '',
            },
            errors: {
              ...initialInputValues
            },
            isSuccess: true,
          }));
        } else {
          this.setState(() => ({
            errors: {
              ...initialInputValues, ...errors
            },
            isSuccess: false,
            message,
          }));
          return Promise.reject(null);
        }
      } catch (error) {
        this.setState(() => ({
          errors: {
            ...initialInputValues, ...error
          }
        }));
        return Promise.reject(null);
      }
    } else {
      return Promise.reject(null);
    }
  };

  onPasswordFieldInput = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      inputs: { ...this.state.inputs, [e.currentTarget.name]: e.currentTarget.value },
      errors: { ...this.state.errors, [e.currentTarget.name]: null },
    });
  };

  onEyeIconClick = (name: currentInput) => {
    switch (name) {
      case currentInput.currentPassword:
        this.setState({ showCurrentPassword: !this.state.showCurrentPassword });
        break;

      case currentInput.newPassword:
        this.setState({ showNewPassword: !this.state.showNewPassword });
        break;

      case currentInput.repeatPassword:
        this.setState({ showRepeatPassword: !this.state.showRepeatPassword });
    }
  };

  onSuccessMessage() {
    setTimeout(() => {
      this.setState({ isSuccess: false });
    }, TIME_SUCCESS_MESSAGE_DISPLAY);
    return <SuccessMessage message={epiPropertyValue(this.props.page.successMessage)} />;
  }

  render() {
    const FormStyle = {
      backgroundColor: 'none',
      padding: {
        x: 0,
        y: pixelsToUnit(30),
      },
    };

    return (
      <MyPagesBaseLayout {...this.props}>
        <Basic layout={Layout.TwoToThree}>
          <AccountForm
            buttonCaption={epiPropertyValue(this.props.page.submitButtonCaption)}
            buttonAction={this.onSubmit}
            customFormStyle={FormStyle}
            successMessage={this.state.isSuccess && this.onSuccessMessage()}
          >
            <PasswordVisibility
              name="currentPassword"
              inputLabel={epiPropertyValue(this.props.page.currentPasswordLabel)}
              inputPlaceholder={epiPropertyValue(this.props.page.currentPasswordLabel)}
              inputValue={this.state.inputs.currentPassword}
              onChange={this.onPasswordFieldInput}
              error={this.state.errors.currentPassword}
            />
            <PasswordVisibility
              name="newPassword"
              inputLabel={epiPropertyValue(this.props.page.newPasswordLabel)}
              inputPlaceholder={epiPropertyValue(this.props.page.newPasswordLabel)}
              inputValue={this.state.inputs.newPassword}
              onChange={this.onPasswordFieldInput}
              error={this.state.errors.newPassword}
            />
            <PasswordVisibility
              name="repeatPassword"
              inputLabel={epiPropertyValue(this.props.page.repeatPasswordLabel)}
              inputPlaceholder={epiPropertyValue(this.props.page.repeatPasswordLabel)}
              inputValue={this.state.inputs.repeatPassword}
              onChange={this.onPasswordFieldInput}
              error={this.state.errors.repeatPassword}
            />
            {!this.state.isSuccess && this.state.message && <ServerError>{this.state.message}</ServerError>}
          </AccountForm>
        </Basic>
      </MyPagesBaseLayout>
    );
  }
}

const ServerError = styled(ErrorMessage, {
  margin: { x: 0, y: pixelsToUnit(20) },
});

export default connect(state => ({
  currentBreakpoint: state.currentBreakpoint,
}))(ChangePassword);

